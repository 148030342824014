<template>
  <section id="hero-alt">
    <v-img
      :gradient="gradient"
      :src="src"
      style="clip-path: polygon(0 0, 100% 0%, 100% 82%, 75% 100%, 25% 100%, 0 79%);"
      color="#45516b"
      flat
      max-width="100%"
      tile
    >
      <v-container>
        <v-row
          v-if="title"
          align="center"
          class="fill-height text-center pt-10"
          justify="center"
        >
          <v-col class="music-hero-left" cols="4">
            <div class="hero-img">
              <v-img
                src="@/assets/images/music-landing/herobgshape@2x.png"
              ></v-img>
            </div>
            <v-img src="@/assets/images/music-landing/heroimg.png"></v-img>
          </v-col>
          <v-col col-sm="6" offset="2" class="text-left white--text">
            <p color class="text-caption">Evelynn Music</p>
            <h1 class="text-h3 mb-4 white--text">Best music app of 2020</h1>
            <p class="text-body-2 mb-6 font-weight-normal">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum.
            </p>
            <v-btn outlined rounded class="white--text" color dark
              >CLICK TO WATCH A VIDEO</v-btn
            >
            <div class="mt-8">
              <v-btn
                color="primary lighten-5"
                rounded
                class="mr-5 primary--text"
                depressed
              >
                <v-icon class="mr-2">mdi-android-debug-bridge</v-icon>Play Store
              </v-btn>
              <v-btn color="primary" rounded depressed>
                <v-icon class="mr-2">mdi-apple</v-icon>App Store
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
// Components
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";

export default {
  name: "SectionHeroAlt",

  metaInfo() {
    return {
      changed: meta => (this.title = meta.titleChunk.toUpperCase())
    };
  },

  provide: {
    heading: { align: "center" }
  },

  data: () => ({
    title: ""
  }),

  computed: {
    gradient() {
      const color = `${"#f11a63"}CC`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
    src() {
      return "https://picsum.photos/1200/500";
    },
    items() {
      return [{ text: "HOME", to: "/" }, { text: this.title }];
    }
  }
};
</script>

<style lang="scss">
#hero-alt {
  .music-hero-left {
    background: url("/assets/images/music-landing/herobgshape@2x.png");
    .hero-img {
      .v-image {
        position: absolute;
        width: 57%;
        top: -200px;
        left: -200px;
        height: 100%;
      }
    }
  }

  .v-breadcrumbs__item {
    color: #ffffff;
  }
}
</style>
